import React, { useState } from 'react'

export const LayoutContext = React.createContext()

const LayoutProvider = props => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <LayoutContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}

export default LayoutContext

export { LayoutProvider }
