/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/tailwind.base.css'
import './src/styles/tailwind.components.css'
import './src/styles/index.css'
import './src/styles/tailwind.utilities.css'

import React from 'react'

import { LayoutProvider } from './src/providers/LayoutProvider'
export const wrapRootElement = ({ element }) => (
  <LayoutProvider>{element}</LayoutProvider>
)
